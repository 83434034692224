// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  cookieDomain: 'recharge.le.ac.uk',
  ga: '',
  title: 'RECHARGE',
  apiUrl: 'https://api-test.recharge.le.ac.uk',
  staticUrl: 'https://static-test.recharge.le.ac.uk',
  avatarPath : '/assets/avatar/',
  testSite: true,
  projectName: 'RECHARGE',
  pwaName: 'RECHARGE',
  languages : [
    {id: 1, language:'English', languageNative: 'English', translationFile : 'en'},
    {id: 2, language:'Hindi', languageNative: 'हिन्दी भाषा', translationFile : 'hindi'},
    {id: 3, language:'Marathi', languageNative:'मराठी', translationFile : 'marathi'},
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
